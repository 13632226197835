import { disable, enable, meta } from "./constant";

export const disableSubmission = val => ({
  type: disable,
  [meta]: val
});

export const enableSubmission = val => ({
  type: enable,
  [meta]: val
});
