export const LOAD_CONFIG = "load_config";
import { fireOneOff } from "@modules/Emission/action";
import { closeModal as closeVerificationModal } from "@modules/OrderVerification/action";
import { disableSubmission, enableSubmission } from "@modules/Checkout/action";
import { sendBugsnagError } from "@utils/Bugsnag";
import { loadScripts } from "@helpers/loadScripts";
import { defaultAction } from "./defaultAction";

import {
  SET_BUY_ORDER_UID,
  SET_FACEBOOK_SDK_LOADED,
  CLEAR_ORDER_ID
} from "@constants/actionTypes";
import {
  registerPreHook,
  registerPostHook,
  hookHandler
} from "@modules/ReduxHook";

// NOTE: Right now this is collocated in the actions folder because this code has yet to be refactored.
registerPreHook(["signal:flow-submit"], [disableSubmission()]);

registerPreHook(["signal:order-reset"], [defaultAction(CLEAR_ORDER_ID)]);

registerPostHook(["signal:session-expired"], [closeVerificationModal()]);

registerPostHook(
  ["signal:flow-start", "signal:flow-end", "signal:flow-checkpoint"],
  [enableSubmission()]
);

// NOTE: This is useful.
registerPostHook(
  [SET_BUY_ORDER_UID],
  hookHandler({
    handle(_, dispatch, getState) {
      const { userActions } = getState();

      dispatch(
        fireOneOff("SELECT_PAY_OPTION", {
          PAY_OPTION: userActions.paymentType
        })
      );
    }
  })
);

////////////////////////////////////////////////////////////////////////////////

export const loadFacebook = () => async (dispatch, getState, api) => {
  try {
    fbAsyncInit();
    loadFbJSSDK();
    dispatch(defaultAction(SET_FACEBOOK_SDK_LOADED));
  } catch (error) {
    sendBugsnagError(error, "actions/loadFacebook");
  }
};

const fbAsyncInit = () => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: "820683028139177",
      cookie: true,
      xfbml: true,
      version: "v2.12"
    });
  };
};

const loadFbJSSDK = () => {
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};
