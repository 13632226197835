import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { defaultAction } from "@actions/defaultAction";
import { sendReferAFriendEmails } from "@actions/refer";
import { validateSingleItem } from "@utils/validators/form";
import { useFormHook } from "@utils/hooks/formHook";
import SubmitButton from "@components/SubmitButton";
import { ClearedStyleInput, ClearedStyleRouterLink } from "@modules/Components";
import { FaTimes } from "react-icons/fa";
import { capitalizeFirstLetter } from "@utils";
import DefaultOrangeButton from "@modules/Components/DefaultOrangeButton";
import { SHOW_REFER_POPUP_MODAL } from "@constants/refer";

const TopFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

const GridItem = styled.div`
  height: 72px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  color: rgba(31, 32, 33, 1);
  position: relative;
  border: ${props => (props.error ? "solid 1px rgba(238, 80, 80, 0.48)" : "")};
  margin-bottom: 13px;

  @media (max-width: 560px) {
    height: 62px;
  }
`;

const NameGridItem = styled(GridItem)`
  @media (max-width: 560px) {
    margin-bottom: 0px;
  }
`;

const GridItemWithShadow = styled(GridItem)`
  box-shadow: 0 20px 70px 0 rgba(34, 35, 36, 0.12);
`;

const InnerInputContainer = styled.div`
  position: relative;
  border-radius: 5px;
  display: flex;
  height: 100%;
`;

const ScrolledInnerInputContainer = styled(InnerInputContainer)`
  overflow-x: auto;
  overflow-y: hidden;
`;

const SmallHeader = styled.div`
  opacity: 0.5;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #1f2021;
  position: absolute;
  top: 16px;
  pointer-events: none;
  padding-left: 19px;
  padding-right: 19px;

  @media (max-width: 560px) {
    font-size: 9px;
  }
`;

const Input = styled(ClearedStyleInput)`
  height: 100%;

  @media (max-width: 560px) {
    font-size: 13px;
  }
`;

const PaddedInput = styled(Input)`
  padding-top: 16px;
  color: #1f2021;
  padding-left: 19px;
  padding-right: 19px;
  width: 100%;
  border-radius: 5px;
`;

const ScrolledInput = styled(PaddedInput)`
  min-width: 222px;
`;

const ErrorMessage = styled.div`
  color: #ee5050;
  font-size: 10px;
  letter-spacing: 1px;
  position: absolute;
  font-weight: 900;
  top: 0;
  left: 20;

  @media (max-width: 560px) {
    font-size: 9px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 108px;
  border-radius: 5px;
  box-shadow: 0 20px 70px 0 rgba(34, 35, 36, 0.12);
  background-color: #ffffff;
  padding: 30px 19px 0px 19px;
  border: ${props => (props.error ? "solid 1px rgba(238, 80, 80, 0.48)" : "")};
  &:hover {
    border: ${props =>
      props.error ? "solid 1px rgba(238, 80, 80, 0.48)" : ""};
  }

  @media (max-width: 560px) {
    font-size: 13px;
  }
  @media (max-width: 400px) {
    height: 129px;
  }
  @media (max-width: 335px) {
    height: 159px;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-left: 19px;
`;

const EmailBox = styled.div`
  border-radius: 5px;
  background-color: rgba(119, 121, 132, 0.1);
  padding: 5px 10px 5px 10px;
  margin-top: 3px;
  margin-left: ${props => props.marginLeft};
  position: relative;
  white-space: nowrap;
`;

const InnerCircle = styled.span`
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 30px;
  box-shadow: 0 6px 12px 0 rgba(31, 32, 33, 0.1);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(119, 121, 132, 0.5);
  cursor: pointer;
  top: -7px;
  right: -7px;
`;

const Success = styled.div`
  text-align: center;
`;

const ThankYouHeader = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: #1f2021;
  margin-bottom: 15px;
  margin-top: -22px;
`;

const ThankYouText = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: #777984;
  margin-bottom: 25px;
`;

const RouterSpan = styled(ClearedStyleRouterLink)`
  color: #ff9c1b;
`;

const ThankYouSpan = styled.span`
  color: #ff9c1b;
  margin-left: 5px;
`;

const ThankYouImageContainer = styled.div`
  min-height: 154px;
`;

const ThankYouImage = styled.img`
  width: 168px;
  height: auto;
  margin-top: 24px;
`;

const ReferAFriendEmailForm = ({
  sendReferAFriendEmails,
  firstName,
  lastName,
  email,
  hidePopUpModal,
  changeEmailSuccess,
  emailSuccess,
  siteHref,
  encodedId
}) => {
  const [emailArray, changeEmailArray] = useState([]);
  const [loading, changeLoading] = useState(false);
  const [formattedName, changeFormattedName] = useState("");
  const [disableButton, setDisabled] = useState(true);
  const [prevLengthOfEmailString, setPrevLengthOfEmailString] = useState(0);

  useEffect(() => {
    if (firstName && lastName) {
      const formatName = `${capitalizeFirstLetter(
        firstName
      )} ${capitalizeFirstLetter(lastName)}`;
      changeFormattedName(formatName);
      handleInputChange({
        target: {
          name: "fromEmail",
          value: email
        }
      });
      handleInputChange({
        target: {
          name: "fromName",
          value: formatName
        }
      });
    }
  }, [firstName, lastName]);

  const formSubmitted = async data => {
    if (disableButton) {
      return;
    }
    changeLoading(true);
    const emailsObject = {
      emails: emailArray,
      message: data.message,
      link: `${siteHref}/sign-up/?utm_source=cardcash&utm_medium=referral&utm_content=${encodedId}`
    };
    const result = await sendReferAFriendEmails(emailsObject);
    if (result) {
      changeEmailSuccess(true);
    }
    clearForm(initialState);
    changeEmailArray([]);
    changeLoading(false);
  };

  const initialState = {
    fromEmail: email,
    fromName: formattedName,
    toEmail: "",
    message:
      "I love using CardCash.com to save money every time I shop and I thought you might too. Use this link to sign-up and you'll receive a $5 coupon to use on your first order! (Minimum $25)"
  };

  const {
    inputs,
    handleInputChange,
    forceSubmit,
    handleBlur,
    disabled,
    formErrors,
    checkIfInputIsValid,
    clearField,
    handleFocus,
    inputActions,
    unfullfilledValues,
    clearForm
  } = useFormHook(formSubmitted, initialState, validateSingleItem, "referForm");

  useEffect(() => {
    const checkToEmail = checkIfInputIsValid("toEmail");
    if (!checkToEmail.errorMessage) {
      const trimmed = inputs.toEmail.trim();
      if (trimmed !== inputs.toEmail) {
        if (inputs.toEmail) {
          changeEmailArray([...emailArray, inputs.toEmail.trim()]);
        }
        resetEmailField();
      }
    } else {
      const specialCharsArray = [",", "."];
      if (specialCharsArray.includes(inputs.toEmail.slice(-1))) {
        const tempEmail = inputs.toEmail.slice(0, -1);
        const recheckEmail = checkIfInputIsValid("toEmail", tempEmail);
        if (!recheckEmail.errorMessage) {
          if (tempEmail) {
            changeEmailArray([...emailArray, tempEmail.trim()]);
          }
          resetEmailField();
        }
      }
    }
  }, [inputs.toEmail]);

  useEffect(() => {
    if (
      inputActions.blurElementName === "toEmail" &&
      !checkIfInputIsValid("toEmail").errorMessage
    ) {
      const emailExistsInArray = emailArray.find(
        item => item === inputs.toEmail.trim()
      );
      if (!emailExistsInArray && inputs.toEmail) {
        changeEmailArray([...emailArray, inputs.toEmail.trim()]);
      }
      resetEmailField();
    }
    inputActions.changeBlurElementName("");
  }, [inputActions.blurElementName]);

  useEffect(() => {
    handleDisableChange();
  }, [disabled, emailArray, inputs]);

  const resetEmailField = () => {
    clearField("toEmail");
    setPrevLengthOfEmailString(0);
    handleBlur({
      target: {
        name: "toEmail",
        value: ""
      }
    });
  };

  const handleDisableChange = () => {
    if (disabled) {
      if (unfullfilledValues.length > 1 || Object.keys(formErrors).length > 1) {
        return setDisabled(true);
      }
      if (
        ((unfullfilledValues.length === 1 &&
          unfullfilledValues[0] === "toEmail") ||
          (Object.keys(formErrors).length === 1 && formErrors.toEmail)) &&
        emailArray.length > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(false);
    }
  };

  const handleEmailKeyUp = e => {
    const backKey = [37, 8];
    if (
      backKey.includes(e.keyCode) &&
      emailArray.length > 0 &&
      !inputs.toEmail
    ) {
      if (prevLengthOfEmailString < 1) {
        const popArray = emailArray;
        const lastElem = popArray.pop();
        changeEmailArray([...popArray]);
        handleInputChange({
          target: {
            name: "toEmail",
            value: lastElem
          }
        });
      } else {
        setPrevLengthOfEmailString(0);
      }
    }
  };

  const handleEmailInputChange = e => {
    setPrevLengthOfEmailString(inputs.toEmail.length);
    handleInputChange(e);
  };

  return (
    <>
      {emailSuccess ? (
        <Success>
          <ThankYouImageContainer>
            <ThankYouImage
              src="https://cdn.cardcash.com/website/ui/elements/check.svg"
              alt="check"
            />
          </ThankYouImageContainer>

          <ThankYouHeader>Thank you!</ThankYouHeader>
          <ThankYouText>
            Thanks for spreading the word about CardCash. After your friend
            places their first eligible order, you will receive your reward. Got
            questions?{" "}
            <ThankYouSpan>
              <RouterSpan
                to={{ pathname: "/faqs/", state: { hash: "refer-a-friend" } }}
                onClick={hidePopUpModal}
              >
                See the FAQ's
              </RouterSpan>
            </ThankYouSpan>
            . We look forward to meeting more of your friends!
          </ThankYouText>
          <DefaultOrangeButton
            classes="refer-email-success-button"
            handleClick={hidePopUpModal}
          >
            <span>Okay</span>
          </DefaultOrangeButton>
        </Success>
      ) : (
        <form onSubmit={forceSubmit}>
          <TopFormGrid className="top-form-grid">
            <NameGridItem error={formErrors && formErrors.fromName}>
              <InnerInputContainer>
                <SmallHeader>FROM</SmallHeader>
                <PaddedInput
                  className="input-placeolder orange-caret"
                  value={inputs.fromName}
                  name="fromName"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder="Your Name"
                  readOnly={true}
                />
              </InnerInputContainer>
              {formErrors && formErrors.fromName && (
                <ErrorMessage className={"animated fadeIn"}>
                  {formErrors.fromName.errorMessage}
                </ErrorMessage>
              )}
            </NameGridItem>
            <NameGridItem error={formErrors && formErrors.fromEmail}>
              <InnerInputContainer>
                <SmallHeader>YOUR EMAIL</SmallHeader>
                <PaddedInput
                  className="input-placeolder orange-caret"
                  value={inputs.fromEmail}
                  name="fromEmail"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder="Your email"
                  readOnly={true}
                />
              </InnerInputContainer>
              {formErrors && formErrors.fromEmail && (
                <ErrorMessage className={"animated fadeIn"}>
                  {formErrors.fromEmail.errorMessage}
                </ErrorMessage>
              )}
            </NameGridItem>
          </TopFormGrid>
          <GridItemWithShadow
            error={formErrors && formErrors.toEmail && !emailArray?.length}
          >
            <ScrolledInnerInputContainer>
              <SmallHeader>TO</SmallHeader>
              {emailArray?.length > 0 && (
                <EmailContainer>
                  {emailArray.map((email, index) => (
                    <EmailBox
                      key={index}
                      marginLeft={index > 0 ? "12px" : "0px"}
                    >
                      {email}
                      <InnerCircle
                        onClick={() => {
                          changeEmailArray(
                            emailArray.filter(item => item !== email)
                          );
                        }}
                      >
                        <FaTimes
                          style={{
                            color: "rgba(31, 32, 33, 0.4)",
                            fontSize: "12px"
                          }}
                        />
                      </InnerCircle>
                    </EmailBox>
                  ))}
                </EmailContainer>
              )}
              <ScrolledInput
                autoCapitalize="off"
                className="input-placeolder orange-caret"
                value={inputs.toEmail}
                name="toEmail"
                onChange={handleEmailInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Recipient's email"
                onKeyUp={handleEmailKeyUp}
              />
            </ScrolledInnerInputContainer>

            {formErrors && formErrors.toEmail && !emailArray?.length && (
              <ErrorMessage className={"animated fadeIn"}>
                {formErrors.toEmail.errorMessage}
              </ErrorMessage>
            )}
          </GridItemWithShadow>

          <TextContainer className="text-container">
            <SmallHeader>MESSAGE</SmallHeader>
            <TextArea
              error={formErrors && formErrors.message}
              className="clear-input-style input-placeolder orange-caret"
              placeholder="Write your message..."
              value={inputs.message}
              name="message"
              onChange={handleInputChange}
              onBlur={handleBlur}
              maxLength={600}
            />

            {formErrors && formErrors.message && (
              <ErrorMessage className={"animated fadeIn"}>
                {formErrors.message.errorMessage}
              </ErrorMessage>
            )}
          </TextContainer>

          <SubmitButton
            disabled={disableButton}
            styles={{ height: "72px", borderRadius: "36px" }}
            isLoading={loading}
          >
            Send Email
          </SubmitButton>
        </form>
      )}
    </>
  );
};

export default connect(
  state => ({
    firstName: state.customerInfo.firstname,
    lastName: state.customerInfo.lastname,
    email: state.customerInfo.email,
    encodedId: state.customerInfo.encodedId,
    siteHref: state.config.siteHref
  }),
  dispatch => ({
    sendReferAFriendEmails: data => dispatch(sendReferAFriendEmails(data)),
    hidePopUpModal: () => dispatch(defaultAction(SHOW_REFER_POPUP_MODAL, false))
  })
)(ReferAFriendEmailForm);
