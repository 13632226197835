import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { loadFacebook } from "@actions";
import { defaultAction } from "@actions/defaultAction";
import { ClearedStyleHref } from "@modules/Components";
import { SHOW_SIGN_IN_POPUP_MODAL } from "@constants/actionTypes";
import { SHOW_REFER_POPUP_MODAL } from "@constants/refer";
import { IoIosArrowBack } from "react-icons/io";
import ReferAFriendEmailForm from "./ReferAFriendEmailForm";
import ReferAFriendFacebook from "./ReferAFriendFacebook";
import ReferCopyLink from "./ReferCopyLink";
import ReferAFriendTwitter from "./ReferAFriendTwitter";
import ReferAFriendMessenger from "./ReferAFriendMessenger";
import NotSignedInRefer from "./NotSignedInRefer";

import { FaChevronDown } from "react-icons/fa";

const Container = styled.div`
  width: 666px;
  padding-bottom: 28px;
  background-color: #f8f8f8;
  border-radius: ${props => props.borderRadius};
  margin: 0 auto;
`;

const MarginContainer = styled.div`
  padding-bottom: 35px;
  padding-top: 25px;
  max-width: 535px;
  margin: 0 auto;
`;

const CloseDiv = styled.div`
  display: flex;
  justify-content: ${props =>
    props.mainDisplay === "form" ? "flex-end" : "space-between"};
  color: #9b9b9b;
  padding-right: 26px;
  padding-left: 26px;
  align-self: flex-start;
  padding-top: 20px;
  min-height: 50px;

  @media (max-width: 500px) {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
  }
`;

const CloseSpan = styled.span`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: ${props => props.setDisplay};
  justify-content: center;
  align-items: center;
`;

const CloseDivImage = styled.img`
  width: 17px;
`;

const InnerContainer = styled.div`
  border-radius: 0px 0px 30px 30px;
  box-shadow: 0 5px 20px 0 rgba(38, 38, 38, 0.05);
  background-color: #ffffff;
`;

const Header = styled.h1`
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  color: #1f2021;
  max-width: 365px;
  margin: 0 auto;

  @media (max-width: 500px) {
    font-size: 26px !important;
    margin-top: -5px !important;
    max-width: 329px !important;
  }
  @media (max-width: 400px) {
    font-size: 22px !important;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const SecondaryHeader = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #777984;
  max-width: 535px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 32px;

  @media (max-width: 520px) {
    padding-right: 5%;
    padding-left: 5%;
  }
  @media (max-width: 485px) {
    font-size: 14px;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 375px) {
    display: none;
  }
`;

const MobileSocialContainer = styled.div`
  @media (min-width: 376px) {
    display: none;
  }

  display: grid;
  grid-template-columns: 71px 119px;
  padding-bottom: 10px;
  padding-left: 13px;
`;

const SelectedSpan = styled.span`
  margin-right: 8px;
`;

const ChangeItemSpan = styled.span`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
`;

const MobileBox = styled.div`
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(38, 38, 38, 0.05);
  border-radius: 0px 0px 5px 5px;
  z-index: 9;
  left: 0px;
  top: 29px;
  width: 119px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TopTextSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ItemLine = styled.div`
  margin-bottom: 5px;
`;

const ContactItem = styled.div`
  font-size: 16px;
  line-height: 2.25;
  text-align: center;
  color: ${props => props.setColor};
  cursor: pointer;
  width: 126px;
  display: grid;
  grid-gap: 15px;
`;

const BottomBorder = styled.div`
  border-radius: 30px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #ff9c1b;
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 14px;
  color: #1f2021;

  @media (max-width: 350px) {
    font-size: 13px;
  }
  @media (max-width: 300px) {
    font-size: 12px;
  }
`;

const HowItWorksButton = styled.div`
  width: fit-content;
  cursor: pointer;
`;

const BackButton = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  color: #777984;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const BackArrow = styled(IoIosArrowBack)`
  color: rgba(119, 121, 132, 0.5);
  font-size: 21px;
  margin-right: 5px;
`;

const HowItWorksText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #1f2021;
  margin-bottom: 20px;

  @media (max-width: 350px) {
    font-size: 15px;
  }
`;

const HowItWorksTextContainer = styled.div`
  margin: 0 auto;
  width: 500px;
  padding-top: 55px;
  padding-bottom: 55px;

  @media (max-width: 550px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const TopContainer = styled.div`
  margin-bottom: 35px;
`;

const LinkSpan = styled.span`
  color: #777984;
`;

const Href = styled(ClearedStyleHref)`
  color: #777984;
  cursor: pointer;
  margin-left: 5px;
`;

const ImageSpan = styled.span`
  @media (max-width: 475px) {
    display: none;
  }
`;

const UrlSpan = styled.span`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: baseline;
  grid-gap: 8px;
  padding-right: 12px;

  @media (max-width: 475px) {
    grid-template-columns: auto;
    padding-right: 0px;
  }
`;

const Image = styled.img`
  display: none;
`;

const Message = styled.div`
  font-size: 14px;
  text-align: center;
  color: #777984;
`;

const NameSpan = styled.span`
  @media (max-width: 475px) {
    padding-left: 7px;
    padding-right: 7px;
  }
`;

const ContactArray = [
  { id: 1, name: "Email" },
  { id: 2, name: "Facebook" },
  { id: 3, name: "Twitter" },
  { id: 4, name: "Messenger" },
  { id: 5, name: "Refer URL" }
];

const ReferComponent = ({
  origin,
  hidePopUpModal,
  siteHref,
  email,
  signedIn,
  showSignInPopup,
  loadFacebook,
  faceSDKLoaded
}) => {
  useEffect(() => {
    if (!faceSDKLoaded) {
      loadFacebook();
    }
  }, []);

  const [selectedId, changeSelectedId] = useState(1);
  const [mainDisplay, changeMainDisplay] = useState("form");
  const [mobileBox, openMobileBox] = useState(false);
  const [emailSuccess, changeEmailSuccess] = useState(false);

  const closeModal = () => {
    hidePopUpModal();
  };

  const fromPopup = origin === "popup" ? true : false;

  return (
    <Container
      onClick={() => mobileBox && openMobileBox(false)}
      borderRadius={fromPopup ? "30px" : "0px"}
      className={`refer-main-container-from-${origin}`}
    >
      <Image
        src="https://cdn.cardcash.com/images/sharing/refer-a-friend.png"
        alt="refer"
      />
      <CloseDiv className="mar-t-10 close-div" mainDisplay={mainDisplay}>
        {mainDisplay === "howItWorks" && (
          <BackButton onClick={() => changeMainDisplay("form")}>
            <BackArrow />
            Back
          </BackButton>
        )}
        <CloseSpan
          onClick={closeModal}
          setDisplay={fromPopup ? "flex" : "none"}
        >
          <CloseDivImage
            src="https://cdn.cardcash.com/website/ui/elements/close.svg"
            aria-hidden="true"
            alt="close button"
          />
        </CloseSpan>
      </CloseDiv>
      {mainDisplay === "form" ? (
        <>
          <Header className="header">
            Invite your friends and you can earn $5 credits
          </Header>
          <SecondaryHeader className="secondary-header">
            Your friends will also receive a $5 coupon after they create an
            account. Please see "How It Works" for complete details and rules.
          </SecondaryHeader>
          <SocialContainer>
            {ContactArray.map(item => (
              <ContactItem
                className="contact-item"
                key={item.id}
                onClick={() => {
                  changeSelectedId(item.id);
                  changeEmailSuccess(false);
                }}
                setColor={item.id === selectedId ? "#1f2021" : "#777984"}
              >
                {item.id === 5 ? (
                  <UrlSpan>
                    <ImageSpan>
                      <img
                        src="https://cdn.cardcash.com/website/ui/elements/cc-link.svg"
                        alt="icon"
                      />
                    </ImageSpan>
                    <NameSpan> {item.name}</NameSpan>
                  </UrlSpan>
                ) : item.id === 1 ? (
                  <NameSpan> {item.name}</NameSpan>
                ) : (
                  <>{item.name}</>
                )}

                {item.id === selectedId && <BottomBorder></BottomBorder>}
              </ContactItem>
            ))}
          </SocialContainer>
          <MobileSocialContainer onClick={e => e.stopPropagation()}>
            <span>Send via: </span>
            <ChangeItemSpan onClick={() => openMobileBox(!mobileBox)}>
              <TopTextSpan>
                <SelectedSpan>
                  {ContactArray.find(item => item.id === selectedId).name}
                </SelectedSpan>
                <span>
                  <FaChevronDown
                    style={{
                      color: "rgba(119, 121, 132, 0.5)",
                      fontSize: "14px",
                      transition: "500ms transform"
                    }}
                  />
                </span>
              </TopTextSpan>
              {mobileBox && (
                <MobileBox onClick={e => e.stopPropagation()}>
                  {ContactArray.filter(item => item.id !== selectedId).map(
                    (item, index) => (
                      <ItemLine
                        key={index}
                        onClick={() => {
                          openMobileBox(false);
                          changeSelectedId(item.id);
                        }}
                      >
                        {item.name}
                      </ItemLine>
                    )
                  )}
                </MobileBox>
              )}
            </ChangeItemSpan>
          </MobileSocialContainer>
          <InnerContainer>
            <MarginContainer className="margin-container">
              {!signedIn ? (
                <NotSignedInRefer showSignInPopup={showSignInPopup} />
              ) : (
                <>
                  {selectedId === 1 ? (
                    <ReferAFriendEmailForm
                      changeEmailSuccess={changeEmailSuccess}
                      emailSuccess={emailSuccess}
                    />
                  ) : selectedId === 2 ? (
                    <ReferAFriendFacebook />
                  ) : selectedId === 3 ? (
                    <ReferAFriendTwitter />
                  ) : selectedId === 4 ? (
                    <ReferAFriendMessenger />
                  ) : (
                    <ReferCopyLink />
                  )}
                </>
              )}
            </MarginContainer>
          </InnerContainer>
          <BottomContainer className="bottom-container">
            {emailSuccess ? (
              <Message>Your rewards will be sent to {email}.</Message>
            ) : (
              <HowItWorksButton onClick={() => changeMainDisplay("howItWorks")}>
                How It Works
              </HowItWorksButton>
            )}
          </BottomContainer>
        </>
      ) : (
        <>
          <TopContainer>
            <Header>How it Works</Header>
          </TopContainer>

          <InnerContainer>
            <HowItWorksTextContainer>
              <HowItWorksText>
                Invite a friend to CardCash and you'll both get rewarded with
                $5!
              </HowItWorksText>
              <HowItWorksText>
                Your friend will receive a $5 coupon immediately after they sign
                up using your referral link, for use on their first order. Once
                your friend completes their first purchase, you too, will also
                receive a $5 coupon reward.
              </HowItWorksText>
              <HowItWorksText>
                Please note, email delivery of this reward coupon, may take up
                to 7 business days after your friend's purchase is complete.
              </HowItWorksText>
              <HowItWorksText>
                Minimum order value for these coupons is $25. Valid for new
                customers only. Only one (1) coupon valid per household and/or
                IP address. Abuse of the referral system is against our TOS, and
                may lead to cancellation and/or a fraud investigation.
              </HowItWorksText>
            </HowItWorksTextContainer>
          </InnerContainer>
          <BottomContainer>
            Need more details?
            <LinkSpan>
              <Href href={`${siteHref}/faqs#refer-a-friend`} target="_blank">
                See our FAQ's
              </Href>
            </LinkSpan>
          </BottomContainer>
        </>
      )}
    </Container>
  );
};

export default connect(
  state => ({
    email: state.customerInfo.email,
    siteHref: state.config.siteHref,
    signedIn: state.initAppReducer.signedIn,
    faceSDKLoaded: state.initAppReducer.faceSDKLoaded
  }),
  dispatch => ({
    hidePopUpModal: () =>
      dispatch(defaultAction(SHOW_REFER_POPUP_MODAL, false)),
    showSignInPopup: initiator =>
      dispatch(defaultAction(SHOW_SIGN_IN_POPUP_MODAL, initiator)),
    loadFacebook: () => dispatch(loadFacebook())
  })
)(ReferComponent);
