import React from "react";
import styled from "@emotion/styled";
import loadable from "@loadable/component";
const CopyLink = loadable(() =>
  import(/* webpackChunkName: "CopyLink" */ "./CopyLink")
);
import { useSelector } from "react-redux";

const Main = styled.div`
  padding-bottom: 38px;
  padding-top: 13px;
`;

const ReferCopyLink = () => {
  const encodedId = useSelector(state => state.customerInfo.encodedId);
  const siteHref = useSelector(state => state.config.siteHref);

  let utm_campaign; // possibly going to set from backend
  const urlString = `${siteHref}/sign-up/?utm_source=cardcash&utm_medium=referral&utm_content=${encodedId}${
    utm_campaign ? `&utm_campaign=${utm_campaign}` : ""
  }`;

  const displayString = `https://www.cardcash.com/?utm_source=card...`;
  const header = "Share using your link below:";

  return (
    <Main>
      <CopyLink
        copyString={urlString}
        displayString={displayString}
        header={header}
      />
    </Main>
  );
};

export default ReferCopyLink;
