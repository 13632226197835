import React from "react";
import styled from "@emotion/styled";
import DefaultOrangeButton from "@modules/Components/DefaultOrangeButton";

const Main = styled.div`
  text-align: center;
`;

const Header = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: #1f2021;
  margin-bottom: 20px;
  margin-top: -12px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: #777984;
  margin-bottom: 25px;
`;

const Image = styled.img`
  width: 168px;
  height: auto;
  margin-top: 24px;
`;

const NotSignedInRefer = ({ showSignInPopup }) => {
  return (
    <Main>
      <Image
        src="https://cdn.cardcash.com/website/ui/elements/signin-icon.svg"
        alt="icon"
      />
      <Header>Please login to continue</Header>
      <Text></Text>
      <DefaultOrangeButton
        classes="refer-email-success-button"
        handleClick={() => showSignInPopup("pageRefer")}
      >
        <span>Login</span>
      </DefaultOrangeButton>
    </Main>
  );
};

export default NotSignedInRefer;
