import React from "react";
import styled from "@emotion/styled";
import { ClearedStyleButton } from "@modules/Components";
import { connect } from "react-redux";

const FacebookMain = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const FacebookButton = styled(ClearedStyleButton)`
  width: 100%;
  height: 72px;
  border-radius: 36px;
  color: #ffffff;
  grid-template-columns: auto 1fr;
  padding-left: 8px;
  cursor: pointer;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1673eb;
  &:hover {
    background-color: #1466d1;
  }
`;

const SocialCircle = styled.div`
  background-color: rgba(38, 38, 38, 0.15);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 9px;
`;

const SocialTextSpan = styled.span`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;

  @media (max-width: 330px) {
    font-size: 14px;
  }
  @media (max-width: 300px) {
    padding-left: 40px;
  }
`;

const ImageContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
  min-height: 266px;

  @media (max-width: 415px) {
    min-height: auto;
  }
`;

const ReferAFriendFacebook = ({ encodedId, siteHref }) => {
  let utm_campaign; // possibly going to set from backend
  const urlString = `https://www.facebook.com/sharer.php?u=${siteHref}/sign-up/?utm_source%3Dcardcash%26utm_medium%3Dreferral%26utm_content%3D${encodedId}${
    utm_campaign ? `%26utm_campaign%3D${utm_campaign}` : ""
  }`;

  return (
    <FacebookMain>
      <ImageContainer>
        <Image
          src="https://cdn.cardcash.com/images/sharing/refer-a-friend.png"
          alt="refer"
        />
      </ImageContainer>
      <a href={urlString} target="_blank" rel="noopener noreferrer">
        <FacebookButton>
          <SocialCircle>
            <img
              src="https://cdn.cardcash.com/website/ui/elements/facebook-icon-small.svg"
              alt="facebook"
            />
          </SocialCircle>
          <SocialTextSpan>Share on Facebook</SocialTextSpan>
        </FacebookButton>
      </a>
    </FacebookMain>
  );
};

export default connect(state => ({
  encodedId: state.customerInfo.encodedId,
  siteHref: state.config.siteHref
}))(ReferAFriendFacebook);
