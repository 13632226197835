import { sendBugsnagError } from "@utils/Bugsnag";

export const sendReferAFriendEmails =
  data => async (dispatch, getState, api) => {
    try {
      const response = await api.post("v3/customers/referrals", { ...data });
      return response.data.success;
    } catch (error) {
      sendBugsnagError(error, "actions/sendReferAFriendEmails");
    }
  };
