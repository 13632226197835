import React from "react";
import styled from "@emotion/styled";
import { ClearedStyleButton } from "@modules/Components";
import { connect } from "react-redux";

const MessengerMain = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const MessengerButton = styled(ClearedStyleButton)`
  width: 100%;
  height: 72px;
  border-radius: 36px;
  color: #ffffff;
  grid-template-columns: auto 1fr;
  padding-left: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0084ff;
  &:hover {
    background-color: #0077e6;
  }
`;

const SocialCircle = styled.div`
  background-color: rgba(38, 38, 38, 0.15);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 9px;
`;

const SocialTextSpan = styled.span`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;

  @media (max-width: 330px) {
    font-size: 14px;
  }
  @media (max-width: 300px) {
    padding-left: 40px;
  }
`;

const ImageContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
  min-height: 266px;

  @media (max-width: 415px) {
    min-height: auto;
  }
`;

const ReferAFriendMessenger = ({ encodedId, siteHref }) => {
  let utm_campaign; // possibly going to set from backend
  const urlString = `${siteHref}/sign-up/?utm_source=cardcash&utm_medium=referral&utm_content=${encodedId}${
    utm_campaign ? `&utm_campaign=${utm_campaign}` : ""
  }`;

  const send = () => {
    if (typeof window !== "undefined" && window.FB) {
      FB.ui({
        method: "send",
        link: urlString
      });
    }
  };
  return (
    <MessengerMain>
      <ImageContainer>
        <Image
          src="https://cdn.cardcash.com/images/sharing/refer-a-friend.png"
          alt="refer"
        />
      </ImageContainer>
      <MessengerButton onClick={send}>
        <SocialCircle>
          <img
            src="http://cdn.cardcash.com/website/ui/elements/messenger-icon2.png"
            alt="messenger"
          />
        </SocialCircle>
        <SocialTextSpan>Share on Messenger</SocialTextSpan>
      </MessengerButton>
    </MessengerMain>
  );
};

export default connect(state => ({
  encodedId: state.customerInfo.encodedId,
  siteHref: state.config.siteHref
}))(ReferAFriendMessenger);
